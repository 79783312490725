import { Activity, MessageStatus } from "@druidsoft/botframework-directlinejs";
import deepmerge from "deepmerge";
import moment from "moment";
import { WebChatState } from "../WebChatContext";

export function druidLanguageComparison(firstLocale: string, secondLocale: string) {
    return firstLocale && secondLocale && (firstLocale.toLocaleLowerCase().startsWith(secondLocale.toLocaleLowerCase()) ||  secondLocale.toLocaleLowerCase().startsWith(firstLocale.toLocaleLowerCase()));
}

export function isNewActivity(currentState: WebChatState, activity: any): boolean {
    return (currentState.directlineInstance?.serverTimestamp || moment()) < moment(activity.timestamp);
}

export function shouldEnter_sensitiveDataInputMode(activity: any): boolean {
    // activity.text === 'prompt test' || 
    return activity.inputHint === 'sensitiveDataInput';

}

export function shouldEnter_fileUploadInputMode(activity: any): boolean {
    // activity.text === 'prompt test' || 
    return activity?.channelData?.flowStepType === 'U';

}

export const AppConsts = { DefaultLanguageId: -1 };

export function getConversationHistoryActivity_isFromMe (convHistoryAct: any): boolean {
    return convHistoryAct.originator === 0 || convHistoryAct.originator === 4;
}
export function getConversationHistoryActivityFrom(convHistoryAct: any): any {
    const role = getConversationHistoryActivity_isFromMe(convHistoryAct) ? 'user' : 'bot_history';
    if(convHistoryAct.originator === 0) {
        return { id: convHistoryAct.abpUserName, name: convHistoryAct.abpUserName,  role};
    }
    if(convHistoryAct.originator === 1) {
        return { id: convHistoryAct.botId, name: convHistoryAct.botName, role };
    }
    if(convHistoryAct.originator === 3) {
        return { id: convHistoryAct.helpdeskAgentName, name: convHistoryAct.helpdeskAgentName, role };
    }
    if(convHistoryAct.originator === 4) {
        return { id: convHistoryAct.helpdeskClientName, name: convHistoryAct.helpdeskClientName, role };
    }
    return null;
}
export function mapConversationHistoryActivityToActivity(convHistoryAct: any, isShown: boolean): any {
    const ret: any = {
        from: getConversationHistoryActivityFrom(convHistoryAct),
        type: 'message',
        text: convHistoryAct.message,
        id: `${convHistoryAct.conversationId}|${convHistoryAct.id}`,
        timestamp: convHistoryAct.dateUtc || convHistoryAct.timestamp,
        status: MessageStatus.Read,
        channelData: deepmerge(convHistoryAct.channelData, { conversationHistory:{ isShown } })
    };
    return ret;
}

// also sets alt value
export function removeTapFromImagesAttachmentIfEmpty(images: any[]) {
    return images.map(img => {
        if(!img.alt) {
            img.alt = 'image';
        }
        if(!img.tap?.value) {
            const {tap, ...withoutTap} = img;
            return withoutTap;
        } else {
            return img;
        }
      });
}
